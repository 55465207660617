module.exports = {
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_TEAMS_DOMAIN: 'https://staging-teams.acloud.guru',
  ACG_SCHOOL_DOMAIN: 'https://staging-learn.acloud.guru',
  ACG_MEMBERSHIP_DOMAIN: 'https://staging-checkout.acloud.guru/signup',
  ACG_VERIFY_DOMAIN: 'https://staging-verify.acloud.guru',
  ACG_INTERNAL_PROFILE_DOMAIN: 'http://localhost:3000',
  ALGOLIA_PUBLIC_SEARCH_KEY: 'bd07683ba0f90f58b2ea5def1ec0cc27',
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  ALGOLIA_COMPANY_NAME_INDEX_NAME: 'prod_companies',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_ROLES_NAMESPACE: 'https://ns.acloud.guru/roles',
  BUGSNAG_API_KEY: 'be470a55cee15da1796290e0fc6827f3',
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  DEFAULT_REDIRECT_URI: 'http://localhost:3000/profile/login/callback',
  GOOGLE_PLACES_API_KEY: 'AIzaSyD7BObL5wzGvxnBmDhdMDMLR4w7-N_mgY4',
  GRAPHQL_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql/',
  PENDO_API_KEY: 'a006fa43-8a84-427f-4cf3-6e601ba0fe76',
  PROFILE_NO_IMAGE: 'https://public.acloud.guru/assets/profile-no-image.png',
  PLACES_APP_ID: 'plGT9D0FCXMK',
  PLACES_SEARCH_KEY: '7e9952c20ba1ddbeed024611c93fe960',
  SSR_ENABLED: true,
  SPLIT_IO_API_KEY: '23jn0mnpvput87lcs5ns2vekp796tgh4ibvu',
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '5a7ca26c0826d72e1c7c9fb4',
  PLURALSIGHT_SKILLS_PROFILE_BASE_URL:
    'https://app-stage.pluralsight.com/profile/',
  SUNSET_PROFILE_REDIRECTION: true,
  CLOUD_PROFILE_EXPERIENCE_ACTIVE: true,
  PLURALSIGHT_OIDC_URL: 'https://app-stage.pluralsight.com/id/signin/sso?',
  PLURALSIGHT_OIDC_ALIAS: 'Alias=acg-test',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout'
};
